/* CSS Drop Shadows
from http://nicolasgallagher.com/css-drop-shadows-without-images/demo/ 
<div class="container">
    <div class="drop-shadow lifted">
        <p>Lifted corners</p>
    </div>
    
    <div class="drop-shadow curled">
        <p>Curled corners</p>
    </div>
    
    <div class="drop-shadow perspective">
        <p>Perspective</p>
    </div>
    
    <div class="drop-shadow raised">
        <p>Raised box</p>
    </div>

    <div class="drop-shadow curved curved-vt-1">
        <p>Single vertical curve</p>
    </div>

    <div class="drop-shadow curved curved-vt-2">
        <p>Vertical curves</p>
    </div>
    
    <div class="drop-shadow curved curved-hz-1">
        <p>Single horizontal curve</p>
    </div>
    
    <div class="drop-shadow curved curved-hz-2">
        <p>Horizontal curves</p>
    </div>


    <div class="drop-shadow lifted rotated">
        <p>Rotated box</p>
    </div>
</div>

*/
.drop-shadow {
    position:relative;
    padding:1em; 
    margin:2em 10px 4em; 
    background:#fff;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 60px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.drop-shadow:before,
.drop-shadow:after {
    content:"";
    position:absolute; 
    z-index:-2;
}

/* Lifted corners */

.lifted {
    -moz-border-radius:4px; 
         border-radius:4px;
}

.lifted:before,
.lifted:after { 
    bottom:15px;
    left:10px;
    width:50%;
    height:20%;
    max-width:300px;
    -webkit-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);   
       -moz-box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
            box-shadow:0 15px 10px rgba(0, 0, 0, 0.7);
    -webkit-transform:rotate(-3deg);    
       -moz-transform:rotate(-3deg);   
        -ms-transform:rotate(-3deg);   
         -o-transform:rotate(-3deg);
            transform:rotate(-3deg);
}

.lifted:after {
    right:10px; 
    left:auto;
    -webkit-transform:rotate(3deg);   
       -moz-transform:rotate(3deg);  
        -ms-transform:rotate(3deg);  
         -o-transform:rotate(3deg);
            transform:rotate(3deg);
}

/* Curled corners */

.curled {
    border:1px solid #efefef; 
    -moz-border-radius:0 0 120px 120px / 0 0 6px 6px;    
         border-radius:0 0 120px 120px / 0 0 6px 6px;
}

.curled:before,
.curled:after {
    bottom:12px;
    left:10px;
    width:50%;
    height:55%;
    max-width:200px;
    -webkit-box-shadow:0 8px 12px rgba(0, 0, 0, 0.5); 
       -moz-box-shadow:0 8px 12px rgba(0, 0, 0, 0.5); 
            box-shadow:0 8px 12px rgba(0, 0, 0, 0.5); 
    -webkit-transform:skew(-8deg) rotate(-3deg);
       -moz-transform:skew(-8deg) rotate(-3deg);
        -ms-transform:skew(-8deg) rotate(-3deg);
         -o-transform:skew(-8deg) rotate(-3deg);
            transform:skew(-8deg) rotate(-3deg);
} 

.curled:after { 
    right:10px; 
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg); 
       -moz-transform:skew(8deg) rotate(3deg);     
        -ms-transform:skew(8deg) rotate(3deg);     
         -o-transform:skew(8deg) rotate(3deg); 
            transform:skew(8deg) rotate(3deg);
}

/* Perspective */

.perspective:before {
    left:80px;
    bottom:5px;
    width:50%;
    height:35%;
    max-width:200px;
    -webkit-box-shadow:-80px 0 8px rgba(0, 0, 0, 0.4);
       -moz-box-shadow:-80px 0 8px rgba(0, 0, 0, 0.4);
            box-shadow:-80px 0 8px rgba(0, 0, 0, 0.4);
    -webkit-transform:skew(50deg);
       -moz-transform:skew(50deg);
        -ms-transform:skew(50deg);
         -o-transform:skew(50deg);
            transform:skew(50deg);
    -webkit-transform-origin:0 100%;
       -moz-transform-origin:0 100%;
        -ms-transform-origin:0 100%;
         -o-transform-origin:0 100%;
            transform-origin:0 100%;
}

.perspective:after {
    display:none;
}

/* Raised shadow - no pseudo-elements needed */

.raised {
    -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

/* Curved shadows */

.curved:before {
    top:10px;
    bottom:10px;
    left:0;
    right:50%;
    -webkit-box-shadow:0 0 15px rgba(0,0,0,0.6);
    -moz-box-shadow:0 0 15px rgba(0,0,0,0.6);
    box-shadow:0 0 15px rgba(0,0,0,0.6);
    -moz-border-radius:10px / 100px;
    border-radius:10px / 100px;
}

.curved-vt-2:before {
    right:0;
}

.curved-hz-1:before {
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}

.curved-hz-2:before {
    top:0;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}

/* Rotated box */

.rotated {
    -webkit-box-shadow:none;
       -moz-box-shadow:none;
            box-shadow:none;
    -webkit-transform:rotate(-3deg);    
       -moz-transform:rotate(-3deg);   
        -ms-transform:rotate(-3deg);
         -o-transform:rotate(-3deg);
            transform:rotate(-3deg);
}

.rotated :first-child:before {
    content:"";
    position:absolute;
    z-index:-1;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:#fff;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 60px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
